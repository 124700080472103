const ELEMENTS = {
  MAIN: '#countdown',
  DAYS: '#countdown-days',
  HOURS: '#countdown-hours',
  MINS: '#countdown-mins',
  SECONDS: '#countdown-seconds',
}

interval = setInterval(() => {
  // noinspection JSCheckFunctionSignatures
  const element = $(ELEMENTS.MAIN)

  if (!element || !element.attr('data-date')) {
    clearInterval(interval)
    return
  }

  const countDownDateValue = element.attr('data-date').replace(/-/g, '/')

  let countDownDate = new Date(`${countDownDateValue}`)
  countDownDate = new Date(
    countDownDate.toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  ).getTime()

  // Get today's date and time
  let now = new Date()
  now = new Date(
    now.toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  ).getTime()

  // Find the distance between now and the count down date
  const distance = countDownDate - now

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  // Display the result in the element with id="element"
  renderValues(element, days, hours, minutes, seconds)

  // If the count down is finished stop interval
  if (distance < 1000) {
    clearInterval(interval)
    renderValues(element, 0, 0, 0, 0)
    location.reload()
  }
}, 1000)

function renderValues(element, days, hours, minutes, seconds) {
  element.find(ELEMENTS.DAYS).text(formatNumber(days))
  element.find(ELEMENTS.HOURS).text(formatNumber(hours))
  element.find(ELEMENTS.MINS).text(formatNumber(minutes))
  element.find(ELEMENTS.SECONDS).text(formatNumber(seconds))
}

function formatNumber(number) {
  // noinspection JSCheckFunctionSignatures
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
}
