$(document).on('turbolinks:load', () => {
  mobileAppBanner()
  donatePopup()
  initGA()
})

function getCookie(key) {
  const name = key + '='
  const cDecoded = decodeURIComponent(document.cookie)
  const cArr = cDecoded.split('; ')
  let res = null
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length)
  })
  return res
}

function setCookie(key, value, expireAt) {
  const date = new Date()
  date.setTime(expireAt)
  const expires = 'expires=' + date.toUTCString()
  let domain = document.domain.split('.')
  domain = domain.slice(Math.max(domain.length - 2, 0)).join('.')
  document.cookie = `${key}=${value};${expires};path=/;domain=.${domain}`
}

function mobileAppBanner() {
  const shouldHide = getCookie('mobileAppBannerHidden')
  const banner = $('#mobile-app-banner')

  if (shouldHide) {
    return banner.remove()
  }

  setTimeout(() => {
    banner.addClass('show')
  }, 10000)
  $('#mobile-app-banner .close-banner').click(() => {
    banner.remove()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    setCookie('mobileAppBannerHidden', true, tomorrow)
  })
}

function donatePopup() {
  if ($('#donateModal').length === 0) return
  if (window.self !== window.top) return

  const delay = parseInt($('#donateModal').attr('show-delay'))
  setTimeout(showModal, delay * 1000)

  function showModal() {
    const shouldHide = getCookie('donate_modal_hide')

    if (!shouldHide) {
      $('#donateModal').modal({ show: true })
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      setCookie('donate_modal_hide', true, tomorrow)
    }

    return null
  }
}

function initGA() {
  initDonateGA()
  initMobileAppGA()
}

function initDonateGA() {
  $('body').on('click', '.ga-tracking-button', (event) => {
    const button = $(event.target)
    if (typeof dataLayer === 'undefined') return

    dataLayer.push({
      event: 'donate_banner_click',
      pageType: button.attr('ga-page-type'),
      pageId: button.attr('ga-page-id'),
      buttonTitle: button.text(),
      bannerDescription: button.attr('ga-banner-description'),
    })
  })

  $('body').on('click', '.donation-project__link', (event) => {
    const button = $(event.target)
    if (typeof dataLayer === 'undefined') return

    dataLayer.push({
      event: 'donate_banner_click',
      pageType: 'Donate projects',
      pageId: button.parent().parent().parent().parent().find('.donation-project__title')
    })
  })
}

function initMobileAppGA() {
  $('body').on('click', '#mobile-app-banner .get-app-button', (event) => {
    const button = $(event.target)
    if (typeof dataLayer === 'undefined') return

    dataLayer.push({
      event: 'mobile_app_click',
      buttonTitle: button.text(),
      clickSource: 'donate_modal'
    })
  })
}
